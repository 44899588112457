import React from 'react'
import { Link, graphql, useStaticQuery } from "gatsby"
import { text_truncate } from "../utils/helpers.js"


const LatestPost = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(
        sort: { order: DESC,
        fields: [published_at] },
        filter: {
          slug: {ne: "data-schema"}
        },
        limit: 1 ) {
        edges {
          node {
            id
            slug
            title
            feature_image
            excerpt
            published_at(formatString: "MMMM DD, YYYY")
            authors {
              id
              slug
            }
          }
        }
      }
    }
  `)
  return(
    <div className="latestPost">
      <div className="latestPost__title">
        <h2 className="major">Վերջին բլոգային գրառումը</h2>
      </div>
      <div className="latestPost__post">
        {data.allGhostPost.edges.map(edge => (
          <div className="latestPost__post--postItem">
            <div className="latestPost__post--postItem-image">
              <img src={edge.node.feature_image} />
            </div>
            <div className="latestPost__post--postItem-content">
              <h2 key={edge.node.id}>
                <Link to={`/blog/${edge.node.slug}`}>
                  {edge.node.title}
                </Link>
              </h2>
              <h5>{edge.node.published_at}</h5>
              <p>{(text_truncate(edge.node.excerpt))}</p>
              <div className="latestPost__post--postItem-content-read_more"
                key={edge.node.id}
              >
                <Link to={`/blog/${edge.node.slug}`}>
                  Read More
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="latestPost__viewMore">
        <h4>Կարդացեք ավելին`</h4>
        <div className="latestPost__viewMore--button">
          <Link
            to='/blog'
            className="continue button alt fit"
          >
          Մուտք
          </Link>
        </div>
      </div>
    </div>
  )
}

export default LatestPost