import React from "react"

import Layout from "../components/layout"
import Intro from "../components/intro"
import About from "../components/about"
import Spotlight from "../components/spotlight"
import Press from "../components/press"
import LatestPost from "../components/latestPost"
// import Gallery from "../components/gallery"

import SEO from "../components/seo"


const IndexPage = () => {
  return (
  <Layout>
    <SEO title="Home" />

    {/* Intro */}
    <Intro />

    {/* About */}
    <About />

    {/* Spotlight */}
    <Spotlight />

    {/* Press */}
    <Press />

    {/* Latest Post */}
    <LatestPost />

    {/* Gallery */}
    {/* <Gallery /> */}

    {/* Contact */}
    {/* <section id="contact" className="contact">
      <div className="contact__inner">
        <h2 id="contact">Բաժանորդագրվեք նորություններին</h2>
        <br />
        <form
          action="https:/mermasin.us16.list-manage.com/subscribe/post?u=f0e1424b6cf749c270c7f3796&amp;id=7574dfdcf6"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate>
          <div className="field third">
            <input type="text" placeholder="Անուն" name="name" id="mce-NAME" required value="" />
          </div>
          <div className="field third">
            <input type="email" placeholder="Էլ. փոստ" name="email" id="mce-EMAIL" required value="" />
          </div>
          <div className="field third">
            <input type="submit" className="alt fit" value="Ուղարկել" id="mc-embedded-subscribe" />
          </div>
          <div id="mce-responses" className="clear">
            <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
            <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
          </div>
        </form>
      </div>
    </section> */}
  </Layout>
)}

export default IndexPage
