import React from 'react'
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import { ExternalLink } from 'react-feather'

const spotlight = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: {
          eq: "Արթուր_Էլբակյան_ՄերՄասին_Երեւան_Գիրք_Arthur Elbakyan_Mer masin_book_Yerevan_Артур Элбакян_2.jpg"
        }) {
        childImageSharp {
          fluid(grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image2: file(relativePath: {
          eq: "Արթուր_Էլբակյան_ՄերՄասին_Երեւան_Գիրք_Arthur Elbakyan_Mer masin_book_Yerevan_Артур Элбакян_Opera.jpg"
        }) {
        childImageSharp {
          fluid(grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image3: file(relativePath: {
          eq: "Արթուր_Էլբակյան_ՄերՄասին_Երեւան_Գիրք_Arthur Elbakyan_Mer masin_book_Yerevan_Артур Элбакян_Prospect.jpg"
        }) {
        childImageSharp {
          fluid(grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      image4: file(relativePath: {
          eq: "Արթուր_Էլբակյան_ՄերՄասին_Երեւան_Գիրք_Arthur Elbakyan_Mer masin_book_Yerevan_Артур Элбакян_KinoNairi.jpg"
        }) {
        childImageSharp {
          fluid(grayscale: true) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
  <section id="spotlights">
      <div className="spotlight">
        <Img
          fluid={ data.image1.childImageSharp.fluid }
          className="spotlight__image"
          loading="lazy"
        />
        <div className="spotlight__content larger_content">
          <h1><span className="special line">Հատվածներ</span></h1>
          <br />
          <h3>0րերից մի օր ձեր նման ես էլ ծնվեցի:</h3>
          <p>
            «Պատմում են, որ աղմուկով եմ ծնվել, ասում են շատ բարձր եմ ճչացել:
            Մեզանից ոչ-ոք չի հիշում, թե ինչպես է ծնվել: Մեզ պատմում են, մենք էլ
            ժպիտով լսում, հարցեր ենք տալիս պատմողներին ու ակնկալում լսել նոր
            մանրամասներ մեր ծննդյան հետ կապված, կարծելով, թե հարյուրերորդ անգամ
            պատմելիս երեւի էլի մի նոր բան կհիշեն: Եվ համարյա ջնջված հիշողությամբ
            մի փոքր էլ հորինելով արդեն հարյուրմեկերորդ անգամ պատմում են մեզ մեր
            ծնվելու պատմությունը։ Եվ շատ կարեւոր է, թե ով է պատմում այն:
            Հարազատ, բարեկամները այդ իրադարձությունը հիշում են յուրովի եւ ամեն
            մեկը կարեւորում է իր հիշածը: Ինչեւէ:
          </p>
          <p>
            Ես աշխարհ եկա: Ծնվեցի Երեւան քաղաքում: Սիլաչի թաղամասի ծննդատանը:
            Քաղաքի այդ օրվա հերթապահ հիվանդանոցում: Ինձնից առաջ ծնվածները արդեն
            ծնվել ու ապրում էին այդ քաղաքում, որտեղ ես էլ պետք է ապրեի ու
            մեծանայի: Քայլեի պիտի նրա փողոցներով, սիրահարվեի իմ քաղաքին ու
            դառնայի երեւանցի: Ինձնից հետո ծնվածներն էլ պետք է ապրեին ու
            մեծանային նույն քաղաքում: Սերունդներ: Մեր մեծերը, իմ հասակակիցները
            եւ կրտսեր սերունդը:»
          </p>
          <br />
          <ul className="actions vertical">
            <li>
              <a href="http:/www.aravot.am/2016/02/03/653310/" className="button fit" target="_blank">
                Շարունակություն
                <ExternalLink size={20} />
              </a>
            </li>
          </ul>
          <hr />
          <h4>Լուսանկարում՝ Արթուր Էլբակյան, Ծաղկաձոր, 1962 թ.:</h4>
        </div>
      </div>
      <div className="spotlight">
        <div className="spotlight__content larger_content">
          <h3>Մեր քաղաքը մեզ սիրում էր:</h3>
          <br />
          <p>«Ու շատ էր սիրում: Ծնողի պես: Առանձնակի ջերմությամբ:
            Երևանը ինձ համար միշտ ժպտացող քաղաք է եղել:
            Ինչ լավ է, որ ես հասցրեցի ապրել ու տեսնել վարդագույն ու ժպտացող Երևանը:
            Ինձ միշտ թվացել է, որ մեր քաղաքը մեր հետ խոսում է:
            Երևանյան փողոցները ինձ հետ խոսում էին: Բարևում էին, շփվում էին, անգամ նեղանում էին, եթե որևէ փողոցում ուշ էի
            հայտնվում:
            </p>
          <p>Փողոցն էլ հիշողություն ունի: Հիշում է` վերջին անգամ, երբ ես քայլել իր մայթերով: Հիշում է` ինչ
              տրամադրություն ունեիր այդ օրը: Փողոց կա` հիշում է քո առաջին սերը, այգի կա, որ հիշում է քո առաջին համբույրը:
              Բակ կա, որ հիշում է քո տղայական առաջին կռիվը:
              Մենք կարծում ենք` միայն մենք ունենք հիշողություն:
              Չէ, դա այդպես չէ: Քաղաքն էլ է հիշում ու կարոտում է մեզ:»
          </p>
        </div>
        <Img
          fluid={ data.image2.childImageSharp.fluid }
          className="spotlight__image"
          loading="lazy"
        />
      </div>
      <div className="spotlight">
        <Img
          fluid={ data.image3.childImageSharp.fluid }
          className="spotlight__image"
          loading="lazy"
        />
        <div className="spotlight__content">
          <h3>Երբ դեռ փոքր էի, ինձ թվում էր, թե «Պրոսպեկտը» շատ երկար է:</h3> 
          <p>«Հետո պարզվեց, որ ընդամենը հինգ կանգառ է տրոլեյբուսով կամ ավտոբուսով:
            Իսկ ոտքով անցնելիս, եթե ծանոթների չէիր հանդիպում ու բռնվում զրույցի, կամ չէիր շեղվում որևէ սրճարան, ապա
            տասնհինգից-քսան րոպե: Մեր պրոսպեկտը...
          </p>
        </div>
      </div>
      <div className="spotlight">
        <div className="spotlight__content">
          <h3>Երևանի իմ սիրած խաչմերուկը:</h3> 
          <p>«Իմ հարազատ թատերական ինստիտուտը, կինո «Նաիրին», Դրամատիկը, Չորրորդ խանութը, թերթի կրպակը, որտեղից «Հայաստանի
            ֆիզկուլտուրնիկ» էի գնում տակից, «Դերասան» կաֆեն, «Պոպլավոկը», «Սկվազնյակը», «Դերասանի տունը» քիչ վերևում
            Մատենադարանը ու Կամերայինը ... ՏՐԱՄՎԱՅԸ:
          </p>
          <p>Ինստիտուտի մայթին՝ ձախ կողմից, խաչմերուկի անկյունում մի թեք «պուլպուլակ» կար: Ինչու էր թեք ոչ-ոք չգիտեր,
            բայց, որ այդ «պուլպուլակի» ջուրը սառն էր ու համեղ, բոլորը գիտեին: Հա ու այդ «պուլպուլակի» կողքին միշտ կվաս էին
            վաճառում...<br />
          </p>
          <p>Այս խաչմերուկում ում ասես կհանդիպեիր: Ողջ քաղաքը ասես այստեղ ժամադրված լիներ՝ այդ խաչմերուկում: Այստեղ էին
              խաչվում արվեստի, արվեսագետների ու մտավորականների տարբեր հոսանքներ։»
          </p>
        </div>
        <Img
          fluid={ data.image4.childImageSharp.fluid }
          className="spotlight__image"
          loading="lazy"
        />
      </div>
    </section>
  )
}

export default spotlight
