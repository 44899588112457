import React from 'react'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"

const Intro = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {
        eq: "Արթուր_Էլբակյան_ՄերՄասին_Երեւան_Գիրք_Arthur Elbakyan_Mer masin_book_Yerevan_Артур_Элбакян_1.jpg"
      }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <section className="banner">
      <div className="banner__content">
        <div className="banner__content--title">
          <h1 className="top">Արթուր Էլբակյան`</h1>
          <h1 className="top">«Մեր մասին» կամ</h1>
          <h1 className="bottom"><span className="special">«Երևան և երևանցիներ»</span></h1>
          <div className="banner__content--title-button">
            <AnchorLink
              to='/#about'
              className="continue button alt fit"
            >
            Մուտք
            </AnchorLink>
          </div>
        </div>
      </div>
      <Img
        fluid={ data.file.childImageSharp.fluid }
        className="banner__image"
      />
    </section>
  )
}

export default Intro
