import React from 'react'

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about__container">
        <h2 className="major">Գրքի մասին</h2>
        <p>
          «Մեր մասին» գրքում իմ ու իմ սերնդի Երեւանն է: Ոչ «օսլայված» Երեւանը,
          այնպիսին, ինչպիսին կար: Ինչպես, որ հիշում եմ: Իր լավով ու վատով, իր
          կենցաղով, մշակույթով ու մարդկանցով: Պարզ ու հասարակ: Գրքիս առիթով շատ
          երեւանցիների հետ զրուցելուց ես հասկացա, որ բոլորը, այդ թվում եւ ես,
          կարոտում կամ հիշում ենք, ոչ թե ինչ-որ շինություն կամ խաչմերուկ, այլ
          մեր անցած ժամանակը, որ թողել ենք այնտեղ: Մեր երտասարդությունը, սերը,
          անհոգ ապրած տարիները: Կարոտում ենք մեր հուշերը:
        </p>
        <p>
          Գիրքը մեր այդ սերնդի ժամանակի Երևանի և երևանցիների մասին է, և առաջին
          հերթին, նրանց համար է: Բայց նաև այն երիտասարդների համար է, ովքեր չեն
          տեսել այդ Երևանը կամ էլ այդ Երևանի մասին պատկերացում ունեն միայն իրենց
          ծնողների պատմություններից: Գրքում չկա քաղաքականություն, չկա
          ատելություն, չկա ինչ-որ մեկին բան սովորեցնելու հավակնություն: Ուղղակի
          գիրք է «մեր» մասին ու մի փոքր էլ` իմ: Կարծում եմ` շատերն իրենց կգտնեն
          այս գրքում, հուսով եմ` շատերի մոտ մոռացված հուշեր կարթնանան, ոմանց էլ
          այն կջերմացնի:
        </p>
        <p className="name">- Արթուր Էլբակյան,<br />Հեղինակ</p>
      </div>
    </section>
  )
}

export default About
