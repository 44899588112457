import React from 'react'
import { ExternalLink } from 'react-feather'

import { text_truncate } from "../utils/helpers.js"

const Press = () => {
  return (
    <section id="press">
      <div className="press">
        <div className="press__title">
          <h2 className="major">Մամուլ</h2>
        </div>
        <div className="press__cards">
          <article className="press__cards--card">
            <h3>0րերից մի օր ձեր նման ես էլ ծնվեցի:</h3>
            <p>{(text_truncate("Պատմում են, որ աղմուկով եմ ծնվել, ասում են շատ բարձր եմ ճչացել: Մեզանից ոչ-ոք չի հիշում, թե ինչպես է ծնվել: Մեզ պատմում են, մենք էլ ժպիտով լսում"))}</p>
            <ul className="actions">
              <li>
                <a href="http://www.aravot.am/2016/02/03/653310/" className="button special fit icon"
                  target="_blank">
                  Շարունակույուն
                  <ExternalLink size={20} />
                </a>
              </li>
            </ul>
          </article>
          <article className="press__cards--card">
            <h3>Արթուր Էլբակյանը՝ «չօսլայած» Երեւանի ու կյանքի կետադրական նշաններն այլ տեղ դնելու մասին:</h3>
            <p>{(text_truncate("Փետրվարի 10-ը հոբելյանական է ռեժիսոր, դերասան, պրոդյուսեր, գրող եւ նկարիչ Արթուր Էլբակյանի համար:"))} </p>
            <ul className="actions">
              <li>
                <a href="http://www.aravot.am/2016/02/04/654079/" className="button special fit icon"
                  target="_blank">
                  Շարունակույուն
                  <ExternalLink size={20} />
                </a>
              </li>
            </ul>
          </article>
          <article className="press__cards--card">
            <h3>Արթուր Էլբակյան. Ինչ անում եմ` անում եմ հայերեն</h3>
            <p>{(text_truncate("Ռեժիսոր, դերասան, պրոդյուսեր, նկարիչ, գրող Արթուր Էլբակյանը երկար տարիներ ապրում է ԱՄՆ-ում, սակայն նկարների գույները հայկական են"))} </p>
            <ul className="actions">
              <li>
                <a href="https://www.panorama.am/am/news/2019/10/14/%D4%B1%D6%80%D5%A9%D5%B8%D6%82%D6%80-%D4%B7%D5%AC%D5%A2%D5%A1%D5%AF%D5%B5%D5%A1%D5%B6/2180912" className="button special fit icon"
                  target="_blank">
                  Շարունակույուն
                  <ExternalLink size={20} />
                </a>
              </li>
            </ul>
          </article>
          <article className="press__cards--card">
            <h3>«Լինելն» այս աշխարհում միակ պատասխանն է</h3>
            <p>{(text_truncate("Արթուր Էլբակյանը սերնդակիցս է, որի հետ բաժին ունեի ապրելու նեղությունների, պատերազմական թոհուբոհի, հայ մարդու համբերության չափաքանակը զննող ժամանակներ:"))} </p>
            <ul className="actions">
              <li>
                <a href="https://www.aravot.am/2015/02/09/540232/" className="button special fit icon"
                  target="_blank">
                  Շարունակույուն
                  <ExternalLink size={20} />
                </a>
              </li>
            </ul>
          </article>
          {/* <article className="press__cards--card">
            <h3>«Մեր սերնդի ապրած կյանքն ասես կտրած հանած լինեն հսկայական կինոժապավենից». Արթուր Էլբակյան</h3>
            <p>{(text_truncate("Արթուր Էլբակյանին շատերը ճանաչում են որպես բեմադրիչ, դերասան, սցենարիստ, ռեժիսոր, հեռուստալրագրող, պրոդյուսեր, բայց շատ քչերը գիտեն, որ նա նաև նկարում է և գրում: Ա. Էլբակյանի առաջին գիրքը`..."))}</p>
            <ul className="actions">
              <li>
                <a href="http://nyut.am/?p=104536&l=am" className="button special fit icon" target="_blank">
                  Շարունակույուն
                  <ExternalLink size={20} />
                </a>
              </li>
            </ul>
          </article> */}
        </div>
      </div>
    </section>
  )
}

export default Press
